
import { getSubsDetails } from '../../../functions/agent/subscriptions/getSubsDetails'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, URL_MAIN_API_APP, USER_AREAS_EXTEND_COUNT, USER_ID_KEY, queryUSER, urlAPI } from '../../../urls'
import { setIsExtend, setNotEnoughMarketingCredits } from '../../slices/advertisesSlice'
import { dataFetchingFinish, dataFetchingStart, modalSlice, setIsAreasForBuyBricks, setShowButtonBuyCoinsOnAdvertise, setShowPopupAddOnsBricks } from '../../slices/modalSlice'
import { type IOrder, userFetchingSuccess, type ISettings } from '../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../store'
import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'
import { getCurrentDateAndNextMonthDate } from '../../../functions/agent/date/getCurrentDateAndNextMonthDate'

export const createSubscriptionAgent = (chooseSubscription: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    const currentUser = getState().user.currentUser
    const userOrders = currentUser.Orders as IOrder[] | null
    const userSettings = currentUser.Settings as ISettings[] | null
    let currentUserCoins = userSettings?.[0]?.MarketingCredits ?? 0
    let currentUserBroadcastCoins = userSettings?.[0]?.BroadcastCredits ?? 0
    currentUserBroadcastCoins = Number(currentUserBroadcastCoins) + Number(getSubsDetails(chooseSubscription).broadcastCoins)
    currentUserCoins = Number(currentUserCoins) + Number(getSubsDetails(chooseSubscription).advertiseCoins)

    const updatedSettings: ISettings | null = (userSettings != null)
        ? {
            ...userSettings[0],
            MarketingCredits: currentUserCoins,
            BroadcastCredits: currentUserBroadcastCoins
        }
        : null

    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        const service = {
            __component: 'services.broker-subscription',
            Package: chooseSubscription,
            ExpiryDate: getCurrentDateAndNextMonthDate().oneMonthLaterFormatted,
            StartDate: getCurrentDateAndNextMonthDate().todayFormatted
        }

        const sendObject = {
            method: 'POST',
            collection: 'orders',
            query: 'populate[Service][fields][0]=*',
            body: {
                Name: `${currentUser.id}_${currentUser.email}_${chooseSubscription}_${new Date().getTime()}`,
                Statuses: ['Paid'],
                Amount: getSubsDetails(chooseSubscription).amount,
                Service: [service],
                User: `${userID}`
            }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(sendObject)
        })

        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())

                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(dataFetchingFinish())
                const userOrdersCopy = Array.isArray(userOrders) ? [...userOrders] : []
                userOrdersCopy.push({ id: dataJSON.response.data.id, ...dataJSON.response.data.attributes })

                const response = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    body: JSON.stringify({

                        method: 'PUT',
                        collection: 'users',
                        query: getQueryCurrentUser(),
                        id: Number(userID),
                        body: {
                            Settings: [updatedSettings],
                            Orders: userOrdersCopy
                        }
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                })
                const responseJSON = await response.json()

                if (responseJSON.success) {
                    if (responseJSON.response.error != null) {
                        dispatch(dataFetchingFinish())
                        return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: '' }
                    } else {
                        dispatch(dataFetchingFinish())
                        dispatch(userFetchingSuccess(responseJSON?.response))
                        return { ...responseJSON, textNavigate: `/agent/modal/agent_settings_for_subscriptions/${userID}/Free`, isSuccessful: true, textNotification: '' }
                    }
                } else {
                    dispatch(dataFetchingFinish())
                    return { textNavigate: '', isSuccessful: false, textNotification: '' }
                }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
    }
}

interface IOptions {
    isLiveMode: boolean
    selectedPeriod: string
    areas?: string[] | undefined
}

export const createSubscriptionAgentWithPaymentMethod = (chooseSubscription: string, unitID?: number, options?: IOptions) => async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log({chooseSubscription, unitID, options})
   
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const currentUser = getState().user.currentUser

    const notEnoughMarketingCredits = getState().advertises.notEnoughMarketingCredits || Number(chooseSubscription.split('_')[2])
    const isAreasForBuyBricks = getState().modal.isAreasForBuyBricks
    const unitIDnotEnoughAreaForAdvertise = getState().advertises.unitIDnotEnoughAreaForAdvertise




    let strapiProductID = 0
    const collection = isAreasForBuyBricks ? 'Areas' : unitID ? 'Unit' : undefined
    const collectionID = unitIDnotEnoughAreaForAdvertise ?? unitID ?? undefined
    const testing = options?.isLiveMode

    let quantity
    let areasExtendCount: undefined | number

    switch (chooseSubscription) {
        case 'Standard':
            strapiProductID = options?.selectedPeriod === 'weekly' ? 7 : options?.selectedPeriod === 'annual' ? 3 : 1
            break
        case 'Premium':
            strapiProductID = options?.selectedPeriod === 'weekly' ? 8 : options?.selectedPeriod === 'annual' ? 4 : 2
            break

        case 'pack_areas_1':
            strapiProductID = 5
            quantity = 1
            areasExtendCount = 1
            break
        case 'pack_areas_3':
            strapiProductID = 5
            quantity = 3
            areasExtendCount = 3
            break
        case 'pack_areas_5':
            strapiProductID = 5
            quantity = 5
            areasExtendCount = 5
            break
        case 'pack_areas_10':
            strapiProductID = 5
            quantity = 10
            areasExtendCount = 10
            break

        case `pack_coins_${notEnoughMarketingCredits}`:
            strapiProductID = 6
            quantity = notEnoughMarketingCredits
            break

        case 'pack_coins_100':
            strapiProductID = 6
            quantity = 100
            break

        case 'pack_coins_200':
            strapiProductID = 6
            quantity = 200
            break
        default:
            break
    }

    try {
        dispatch(dataFetchingStart())
        const sendObject = { email: currentUser.email, id: Number(strapiProductID), collection, collectionID, quantity, testing , areas : options?.areas}

        const response = await fetch(`${URL_MAIN_API_APP}/payment/createInvoice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(sendObject)
        })
        const dataJSON = await response.json()
        if (getState().advertises.notEnoughMarketingCredits > 0) {
            dispatch(setNotEnoughMarketingCredits(0))
        }
        console.log(dataJSON,"dataJSON")
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(dataFetchingFinish())
                // if (areasExtendCount && areasExtendCount > 0 && Boolean(unitID)) {
                //     window.localStorage.setItem(USER_AREAS_EXTEND_COUNT, `${unitID} - ${areasExtendCount}`)
                //     if (areasExtendCount === 1) {
                //         dispatch(setIsExtend(true))
                //     }
                // }
                dispatch(setShowButtonBuyCoinsOnAdvertise(false))
                dispatch(setShowPopupAddOnsBricks(false))
                dispatch(setNotEnoughMarketingCredits(0))
                dispatch(setIsAreasForBuyBricks(false))
                return { ...dataJSON.response, textNavigate: '', isSuccessful: true, textNotification: 'Please make payment' }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
    }
}
