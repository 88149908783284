import { type Unit } from '../../../store/slices/unitsSlice'
import { type IViewing } from '../../../store/slices/viewingsSlice'
import { USER_ID_KEY } from '../../../urls'
import { getCurrentDubaiDateString } from './getCurrentDateStrFormat'

export function isReservation (filterDate: string, time: string, viewings: any, currentUnit: any, isKeyBookingProcess: boolean = false):{
    isFreeCeil: boolean
    isReservCeilOtherUsers: boolean
    isReservCeilCurrentUser: boolean
} {
    const currentUserID = window.localStorage.getItem(USER_ID_KEY)
    const isShowTimeOtherAgents = currentUnit?.attributes?.Statuses.includes('Digital lock') || currentUnit?.attributes?.Statuses.includes('Door open')
    const disabledTimes = currentUnit?.attributes?.DisabledTimes
   
    try {
        let dateChoose = new Date(getCurrentDubaiDateString())
        if (filterDate && time) {
            dateChoose = new Date(`${filterDate}T${time}`)
        }

        const result = {
            isFreeCeil: false,
            isReservCeilOtherUsers: false,
            isReservCeilCurrentUser: false 
        }
      
        // // Проверка disabledTimes на совпадение по времени
        // if (disabledTimes?.length > 0) {
        //     const isInDisabledTimeRange = disabledTimes.some((disabledTime: { From: string, To: string }) => {
        //         const fromTime = new Date(`${filterDate}T${disabledTime.From}`);
        //         const toTime = new Date(`${filterDate}T${disabledTime.To}`);
        //         return dateChoose >= fromTime && dateChoose <= toTime;
        //     });
        
        //     if (isInDisabledTimeRange) {
        //         result.isReservCeilOtherUsers = true;
        //         return result; 
        //     }
        // }


        // if (isKeyBookingProcess) {
        //     const viewings = store.getState().viewings.viewings.filter((viewing) => currentUnit.attributes.viewings.data.some((viewingItem: any) => viewing.id === viewingItem.id))
        //     const keyBookings = viewings.map((viewing) => viewing.attributes.KeyBooking.data)
        // }

        if ('KeysBookings' in currentUnit?.attributes && currentUnit?.attributes?.KeysBookings?.data !== null) {
            if (currentUnit?.attributes?.KeysBookings?.data.length > 0) {
                const isDateWithinUnitKeys = currentUnit?.attributes?.KeysBookings?.data?.some(
                    (unitKey: any) => {
                        if (unitKey?.attributes?.PickUpDate && unitKey?.attributes?.DropOutDate) {
                            const pickUpDate = new Date(unitKey?.attributes?.PickUpDate)
                            const dropOutDate = new Date(unitKey?.attributes?.DropOutDate)

                            const pickUpDateDubai = new Date(pickUpDate.toLocaleString('en-US', { timeZone: 'Asia/Dubai' }))
                            const dropOutDateDubai = new Date(dropOutDate.toLocaleString('en-US', { timeZone: 'Asia/Dubai' }))

                            const isCurrentUserKey = unitKey?.attributes?.Holder?.data?.id === Number(currentUserID)

                            if (dateChoose >= pickUpDateDubai && dateChoose <= dropOutDateDubai && (currentUserID !== process.env.REACT_APP_BSO_USER_ID)) {
                                if (isCurrentUserKey) {
                                    result.isReservCeilCurrentUser = true
                                } else {
                                    result.isReservCeilOtherUsers = true
                                }
                            }
                        }
                        result.isFreeCeil = true
                    }
                )

                if (isDateWithinUnitKeys) {
                    return result
                }
            }
        }
        viewings.some((view: IViewing) => {
            const viewDate = new Date(view?.attributes?.Datetime)
            const viewDateDubaiTime = viewDate.toLocaleString('en-US', { timeZone: 'Asia/Dubai' })

            const chosenDateTime = new Date(dateChoose)
            const viewDateDubai = new Date(viewDateDubaiTime)

            if (!isKeyBookingProcess) {
                if (
                    viewDateDubai.getUTCFullYear() === chosenDateTime.getUTCFullYear() &&
                    viewDateDubai.getUTCMonth() === chosenDateTime.getUTCMonth() &&
                    viewDateDubai.getUTCDate() === chosenDateTime.getUTCDate() &&
                    viewDateDubai.getUTCHours() === chosenDateTime.getUTCHours() &&
                    viewDateDubai.getUTCMinutes() === chosenDateTime.getUTCMinutes()
                ) {
                    const viewStatuses = view?.attributes?.Statuses ?? []
                    if (viewStatuses.includes('In-process') || viewStatuses.includes('Approved')) {
                        const isCurrentUserView = view?.attributes?.User.data?.id === Number(currentUserID)
                        if (isCurrentUserView) {
                            result.isReservCeilCurrentUser = true
                        } else {
                            if (!isShowTimeOtherAgents && viewStatuses.includes('Approved')) {
                                result.isReservCeilOtherUsers = true
                            }
                        }
                    }
                }
                if ('KeyBooking' in view?.attributes && view?.attributes?.KeyBooking?.data !== null) {
                    const keyBooking = view?.attributes?.KeyBooking?.data
                    if (keyBooking?.attributes?.PickUpDate && keyBooking?.attributes?.DropOutDate) {
                        const pickUpDate = new Date(keyBooking?.attributes?.PickUpDate)
                        const dropOutDate = new Date(keyBooking?.attributes?.DropOutDate)
                        const pickUpDateDubai = new Date(pickUpDate.toLocaleString('en-US', { timeZone: 'Asia/Dubai' }))
                        const dropOutDateDubai = new Date(dropOutDate.toLocaleString('en-US', { timeZone: 'Asia/Dubai' }))
                        const isCurrentUserKeyBooking = keyBooking?.attributes?.Holder?.data?.id === Number(currentUserID)

                        if (dateChoose >= pickUpDateDubai && dateChoose <= dropOutDateDubai && (currentUserID !== process.env.REACT_APP_BSO_USER_ID)) {
                            if (isCurrentUserKeyBooking) {
                                result.isReservCeilCurrentUser = true
                            } else {
                                result.isReservCeilOtherUsers = true
                            }
                        }
                    }
                }
            } else {
                if ('KeyBooking' in view?.attributes && view?.attributes?.KeyBooking?.data !== null) {
                    const keyBooking = view?.attributes?.KeyBooking?.data
                    if (keyBooking?.attributes?.PickUpDate && keyBooking?.attributes?.DropOutDate) {
                        const pickUpDate = new Date(keyBooking?.attributes?.PickUpDate)
                        const dropOutDate = new Date(keyBooking?.attributes?.DropOutDate)
                        const pickUpDateDubai = new Date(pickUpDate.toLocaleString('en-US', { timeZone: 'Asia/Dubai' }))
                        const dropOutDateDubai = new Date(dropOutDate.toLocaleString('en-US', { timeZone: 'Asia/Dubai' }))
 
                        const isCurrentUserKeyBooking = keyBooking?.attributes?.Holder?.data?.id === Number(currentUserID)
                        if (dateChoose >= pickUpDateDubai && dateChoose <= dropOutDateDubai && (currentUserID !== process.env.REACT_APP_BSO_USER_ID || isKeyBookingProcess)) {
                            if (isCurrentUserKeyBooking) {
                                result.isReservCeilCurrentUser = true
                            } else {
                                result.isReservCeilOtherUsers = true
                            }
                        }
                    }
                }
            }

            result.isFreeCeil = true
        })
        //  console.log(result, 'result')
        return result
    } catch (error) {
        console.log(error)
        return {
            isFreeCeil: false,
            isReservCeilOtherUsers: false,
            isReservCeilCurrentUser: false
        }
    }
}
export function unitReservations (currentUnit: any, dateChooseViewing: any): boolean | undefined {
    const chooseDateViewing = new Date(dateChooseViewing)
    if ('KeysBookings' in currentUnit?.attributes && currentUnit?.attributes.KeysBookings.data !== null) {
        if (currentUnit?.attributes.KeysBookings.data.length > 0) {
            const isDateWithinUnitKeys = currentUnit?.attributes.KeysBookings.data?.some(
                (unitKey: any) => {
                    //      console.log(unitKey, 'unitKey')
                    if (unitKey?.attributes?.PickUpDate && unitKey?.attributes?.DropOutDate) {
                        const pickUpDate = new Date(unitKey?.attributes?.PickUpDate)
                        const dropOutDate = new Date(unitKey?.attributes?.DropOutDate)
                        const pickUpDateDubai = new Date(pickUpDate.toLocaleString('en-US', { timeZone: 'Asia/Dubai' }))
                        const dropOutDateDubai = new Date(dropOutDate.toLocaleString('en-US', { timeZone: 'Asia/Dubai' }))

                        //      console.log({ pickUpDateDubai, dropOutDateDubai, chooseDateViewing })
                        // console.log(dateChooseViewing)

                        //  return dateChoose >= pickUpDateDubai && dateChoose <= dropOutDateDubai
                    }
                    return false
                }
            )

            if (isDateWithinUnitKeys) {
                return true // Забитый период
            }
        }
    }
}
