import React from 'react'
import { getSubsDetails } from '../../../../functions/agent/subscriptions/getSubsDetails'

import subscriptionTexts from '../../../../app/texts/subscriptionTexts'
import Icon from '../../../../app/icon/Icon'

interface IProps {
    selectedSubscription: string
    selectedPeriod: string
    setSelectedSubscription: any
    activeSub: string
    activeSubPeriod: string
    isDev?: boolean
}
export default function GetDescriptions({
    selectedSubscription,
    isDev,
    activeSub,
    selectedPeriod,
    activeSubPeriod,
    setSelectedSubscription
}: IProps): JSX.Element {

    return (
        <>
            <div className="pt-1 px-2 d-flex flex-column justify-content-between mt-2">
                {isDev &&
                    <>
                        <div className="d-flex align-items-center ">
                            <span className="fw-semibold fs-6 text-gray-800 flex-grow-1">

                                {subscriptionTexts.field_brodcoast}
                            </span>
                            <Icon name={'check_circle'} className={'fs-3x text-primary'} />
                        </div>
                        <div className="separator separator-dashed my-2"></div>
                    </>
                }
                <div className="d-flex align-items-center ">
                    <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 lh-1">
                        {subscriptionTexts.field_chat}
                    </span>
                    <Icon name={'check_circle'} className={'fs-3x text-primary'} />
                </div>
                <div className="separator separator-dashed my-2 lh-1"></div>
                <div className="d-flex align-items-center">
                    <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 lh-1">
                        {subscriptionTexts.field_schedule}
                    </span>
                    <Icon name={'check_circle'} className={'fs-3x text-primary'} />
                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex align-items-center">
                    <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 lh-1">
                        {subscriptionTexts.field_areas}
                    </span>

                    {selectedSubscription === 'Premium'
                        ? (
                            <span className="badge badge-lg badge-light-primary border border-primary">
                                {getSubsDetails(selectedSubscription).areas}
                            </span>
                        )
                        : (
                            <span className={`badge ${selectedSubscription === 'Premium' ? '' : 'badge-circle'} badge-outline badge-primary badge-lg fs-6`}>
                                {getSubsDetails(selectedSubscription).areas}
                            </span>
                        )
                    }
                </div>
                <div className="separator separator-dashed my-2"></div>
                {/* {isDev &&
                    <> <div className="d-flex align-items-center">
                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 lh-1">

                            {subscriptionTexts.field_broadcoast_coins}
                        </span>
                        <span className="badge badge-circle badge-outline badge-primary badge-lg fs-6 ">
                            {getSubsDetails(selectedSubscription).broadcastCoins}
                        </span>
                    </div>
                        <div className="separator separator-dashed my-2"></div>
                    </>
                } */}

                <div className="d-flex align-items-center mb-2">
                    <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 lh-1">
                        {subscriptionTexts.field_marketing_coins}
                    </span>
                    <span className="badge badge-circle badge-outline badge-primary badge-lg fs-6 ">
                        {getSubsDetails(selectedSubscription).advertiseCoins}
                    </span>
                </div>

                {/* <div className="separator separator-dashed my-2"></div>
                <div className="d-flex align-items-center ">
                    <span className="fw-semibold fs-5 text-gray-800 flex-grow-1">
            Limitations on viewings{' '}
                    </span>
                    <i className="ki-duotone ki-cross-circle  fs-2x text-white">
                        <span className="path1 text-gray-800"></span>
                        <span className="path2"></span>
                    </i>
                </div>
                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex align-items-center ">
                    <span className="fw-semibold fs-5 text-gray-800 flex-grow-1">
            Limitations on areas{' '}
                    </span>
                    <i className="ki-duotone ki-cross-circle fs-2x text-white">
                        <span className="path1 text-gray-800"></span>
                        <span className="path2"></span>
                    </i>
                </div> */}
            </div>
        </>
    )
}
