
import { getCorrectExpDate } from '../../../../functions/agent/date/getCorrectExpDateForScaning'
import getAgentStatuses from '../../../../functions/agent/documents/checkDocumentsForAproved'
import { getCorrectGender, getCountryCodes } from '../../../../functions/getDataForSelects/getCountries'
import { documentTypes } from '../../../../urls'
import { setStatuses } from '../../../slices/agentSlice'
import { authSlice } from '../../../slices/authSlice'
import { documentsSlice, setMaxAttemptsScan } from '../../../slices/documentSlice'
import { dataFetchingFinish, dataFetchingStart } from '../../../slices/modalSlice'
import { type User } from '../../../slices/userSlice'
import { store, type AppDispatch, type RootState } from '../../../store'

import { archiveDocument } from '../archiveDocument'
import { checkDocument } from '../checkDocument'
import { scanDocument } from '../scaning/documentScan'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { setCreatePropertyFields } from '../../../slices/propertySlice'
import { checkDocForProbabilityAgentName } from '../../../../functions/agent/documents/checkDocForProbabilityAgentName'
import { uploadFiles } from './uploadFiles'
import { checkUserDocumentIfItUseOtherUser } from '../checkUserDocumentIfItUseOtherUser'

export const uploadFile = (formValues: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const maxAttemptsScan  = getState().documents.maxAttemptsScan
        dispatch(authSlice.actions.authFetching(true))
        dispatch(dataFetchingStart())
        const currentUser = getState().user.currentUser as User
        const docsAgent = getState().documents?.documents.filter((doc) => doc.attributes.User.data?.id === currentUser.id)
        const usDateB = currentUser?.ContactInfo?.DateOfBirth
        const fileTypesToUpload = []
        const scanDoc = await dispatch(scanDocument(formValues))
        formValues.ForRegister = !('isClientDocument' in formValues && formValues.isClientDocument) 
        if ('visa' in formValues) {
            fileTypesToUpload.push('visa')
            if (scanDoc != null) {
                const scanDocument = scanDoc?.document
                if (scanDoc?.type !== 'Passport') {
                    dispatch(dataFetchingFinish())
                    return { textNavigate: formValues?.redirect || '', isSuccessful: false, textNotification: `Only ${formValues?.type_document_page.includes('passport') ? 'Passport' : 'Emirates ID'} is loaded in this section, please select the correct document type.` }
                } else {
                    if ("valid" in scanDocument && scanDocument?.valid) {
                        formValues.visa_expiry = getCorrectExpDate(scanDocument?.expDate ?? '')
                        formValues.dob = scanDocument?.dob ? getCorrectExpDate(scanDocument?.dob) : ""
                        if (scanDocument?.gender) {
                            formValues.gender = getCorrectGender(scanDocument.gender);
                        }
                        if (scanDocument?.nationality ) {
                            formValues.nationality =  getCountryCodes(scanDocument?.nationality)
                        }
                        
                   
                        formValues.name = scanDocument?.name ?? ''
                        formValues.number = scanDocument?.number ?? ''
                        formValues.status = scanDocument?.valid as boolean ? 'Approved' : 'In-process'
                    
                        const checkDoc = await checkDocument(formValues, fileTypesToUpload)
                        if (checkDoc) {
                            dispatch(dataFetchingFinish())
                            return { textNavigate: formValues?.redirect || '', isSuccessful: false, textNotification: 'This document is used by the other user' }
                        }
                        const useOtherAgent = await checkUserDocumentIfItUseOtherUser(formValues)
                        if(useOtherAgent){
                            dispatch(dataFetchingFinish())
                            formValues.status = 'In-process'
                            formValues.textNotification = 'Your document was not recognized perfectly, Within 24 hours we will confirm your documents and you will be able to use the corresponding functionality'
                        } else {
                            if (formValues.isLoadedDocumentFromSettings) {
                                const probability = checkDocForProbabilityAgentName(scanDoc?.document?.name)
                                if (probability < 55  && usDateB === formValues.dob) {
                                    formValues.status = 'Approved'
                                } else {
                                    formValues.status = 'In-process'
                                    formValues.textNotification = 'Your document was not recognized perfectly, Within 24 hours we will confirm your documents and you will be able to use the corresponding functionality'
                                }
                            }
                        }
                    } else {
                        dispatch(dataFetchingFinish())
                        return { textNavigate: formValues?.redirect || '', isSuccessful: false, textNotification: 'Your Passport cannot be recognized.\nPlease try again' }
                    }
                }
            } else {
                if(maxAttemptsScan > 2){
                    formValues.status = 'In-process'
                    formValues.textNotification = "We encountered several unsuccessful attempts during the scanning process. Our team will <strong>manually review</strong> this document within <strong>the next 24 hours</strong>. However, <strong>some functionality</strong> within the app <strong>will be limited</strong> during this time.\nIf you <strong>agree</strong>, please click <strong>\"Request Manual Review.\"</strong> You can <strong>also update</strong> the document at <strong>any time</strong> and try again by going to <strong>\"Settings\"</strong> in your profile, or you can choose <strong>to retry now</strong>."
                    formValues.maxAttemptsWarning = true           
                    if(formValues.type_document_page === "register_scan_passport"){
                        formValues.redirect = "/auth/register_scan_passport"
                    }   
                } else {
                    dispatch(dataFetchingFinish())
                    dispatch(setMaxAttemptsScan(maxAttemptsScan + 1))
                    return { textNavigate: '', isSuccessful: false, textNotification: 'Your Passport cannot be recognized.\nPlease try again' }
                }
               
            }
        }
        if ('rera' in formValues) {
            if (scanDoc !== null) {
                fileTypesToUpload.push('rera')
                if (scanDoc !== null) {
                    if (scanDoc?.document?.expDate) {
                        formValues.rera_expiry = getCorrectExpDate(scanDoc?.document?.expDate ?? '')
                        formValues.number = scanDoc?.document?.number ?? ''
                        formValues.status = scanDoc?.document?.valid ? 'Approved' : 'In-process'
                        formValues.name = scanDoc?.document?.name ?? ''
                        formValues.companyName = scanDoc?.document?.companyName ?? ''
                        formValues.rera_issue = getCorrectExpDate(scanDoc?.document?.issueDate ?? '')

                        const checkDoc = await checkDocument(formValues, fileTypesToUpload)
                        if (checkDoc) {
                            dispatch(dataFetchingFinish())
                            return { textNavigate: -1, isSuccessful: false, textNotification: 'RERA is used by another user' }
                        }

                        if (formValues.isLoadedDocumentFromSettings) {
                            const probability = checkDocForProbabilityAgentName(scanDoc?.document?.name)
                            if (probability < 55) {
                                dispatch(dataFetchingFinish())
                                return { textNavigate: formValues?.redirect || '', isSuccessful: false, textNotification: 'This RERA card doesn\'t match your name.' }
                            }
                        }
                    } else {
                        dispatch(dataFetchingFinish())
                        return { textNavigate: '', isSuccessful: false, textNotification: 'Your RERA card is expired' }
                    }
                } else {
                    dispatch(dataFetchingFinish())
                    return { textNavigate: formValues?.redirect || '', isSuccessful: false, textNotification: 'Your RERA card cannot be recognized.\nPlease try again' }
                }
            } else {
                dispatch(dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: 'Your RERA card cannot be recognized.\nPlease try again' }
            }
        }
        if ('id_front' in formValues) {
            fileTypesToUpload.push('id_front')
            if (scanDoc !== null) {
                const scanDocument = scanDoc?.document
                if (!(scanDoc?.type == 'Emirates ID' || scanDoc?.type == 'Emirates ID Front')) {
                    dispatch(dataFetchingFinish())
                    return { textNavigate: formValues?.redirect || '', isSuccessful: false, textNotification: `Only ${formValues?.type_document_page.includes('emirates') ? 'Emirates ID' : 'Passport'} is loaded in this section, please select the correct document type.` }
                } else {
                   
                    formValues.dob = scanDocument?.dob ? getCorrectExpDate(scanDocument?.dob) : ""
                    formValues.name = scanDocument.name ?? ''
                    formValues.number = scanDocument.number ?? ''
                    formValues.status = scanDocument.valid as boolean ? 'Approved' : 'In-process'
                    if (scanDocument?.gender) {
                        formValues.gender = getCorrectGender(scanDocument.gender);
                    }
                    if (scanDoc?.document?.nationality) {
                        formValues.nationality =   getCountryCodes(scanDoc?.document?.nationality)
                    }
                    const checkDoc = await checkDocument(formValues, fileTypesToUpload)
                    // CHEEECK
                    if (checkDoc === true) {
                        dispatch(dataFetchingFinish())
                        return { textNavigate: '', isSuccessful: false, textNotification: 'This document is used by the other user'  }
                    }
                    const useOtherAgent = await checkUserDocumentIfItUseOtherUser(formValues)
                    if(useOtherAgent === true){
                        dispatch(dataFetchingFinish())
                        return { textNavigate: '', isSuccessful: false, textNotification: 'There is already an account using documents with this name' }
                    } 

                }
            } else {
                if(maxAttemptsScan > 2){
                    formValues.status = 'In-process'
                    formValues.textNotification = "We encountered several unsuccessful attempts during the scanning process. Our team will <strong>manually review</strong> this document within <strong>the next 24 hours</strong>. However, <strong>some functionality</strong> within the app <strong>will be limited</strong> during this time.\nIf you <strong>agree</strong>, please click <strong>\"Request Manual Review\".</strong> You can <strong>also update</strong> the document at <strong>any time</strong> and try again by going to <strong>\"Settings\"</strong> in your profile, or you can choose <strong>to retry now</strong>."
                    formValues.maxAttemptsWarning = true    
                } else {
                    dispatch(dataFetchingFinish())
                    dispatch(setMaxAttemptsScan(maxAttemptsScan + 1))
                    return { textNavigate: '', isSuccessful: false, textNotification: 'Your Emirates ID cannot be recognized.\nPlease try again' }
                }
            }
        }
        if ('id_back' in formValues) {
            fileTypesToUpload.push('id_back')
            if (scanDoc !== null) {
                if (scanDoc?.type !== 'Emirates ID') {
                    dispatch(dataFetchingFinish())
                    return { textNavigate: formValues?.redirect || '', isSuccessful: false, textNotification: `Only ${formValues?.type_document_page.includes('emirates') ? 'Emirates ID' : 'Passport'} is loaded in this section, please select the correct document type.` }
                } else {                  
                    if ( 'valid' in  scanDoc?.document && scanDoc?.document?.valid) {
                        formValues.id_back_expiry = getCorrectExpDate(scanDoc?.document?.expDate ?? '') ?? ''
                        formValues.dob = getCorrectExpDate(scanDoc?.document?.dob ?? '') ?? ''
                        formValues.name = scanDoc?.document?.name ?? ''
                        formValues.number = scanDoc?.document?.number ?? ''
                        formValues.status = scanDoc?.document?.valid ? 'Approved' : 'In-process'
                        if (scanDoc?.document?.gender) {
                            formValues.gender =  getCorrectGender(scanDoc?.document?.gender)
                        }
                        if (scanDoc?.document?.nationality) {
                            formValues.nationality =   getCountryCodes(scanDoc?.document?.nationality)
                        }

                        const checkDoc = await checkDocument(formValues, fileTypesToUpload)
                        if (checkDoc) {
                            dispatch(dataFetchingFinish())
                            return { textNavigate: formValues?.redirect || '', isSuccessful: false, textNotification: 'This document is used by the other user' }
                        }

                        const useOtherAgent = await checkUserDocumentIfItUseOtherUser(formValues)
                        if(useOtherAgent){
                            dispatch(dataFetchingFinish())
                            formValues.status = 'In-process'
                            formValues.textNotification = 'Your document was not recognized perfectly, Within 24 hours we will confirm your documents and you will be able to use the corresponding functionality'
                        } else {
                            if (formValues.isLoadedDocumentFromSettings) {
                                const oldDoc = currentUser?.Type?.[0]?.EmiratesIDNumber
                                if (oldDoc && oldDoc.length > 5) {
                                    if (oldDoc !== formValues.number) {
                                        dispatch(dataFetchingFinish())
                                        return { textNavigate: formValues?.redirect || '', isSuccessful: false, textNotification: 'Your Emirates ID number does not match the one you uploaded at registration' }
                                    } else {
                                        const probability = checkDocForProbabilityAgentName(scanDoc?.document?.name)
                                        if (probability < 55  && usDateB === formValues.dob) {
                                            formValues.status = 'Approved'
                                            formValues.textNotification = 'Your Emirates ID has been successfully uploaded and approved'
                                        } else {
                                            formValues.status = 'In-process'
                                            formValues.textNotification = 'Your document was not recognized perfectly, Within 24 hours we will confirm your documents and you will be able to use the corresponding functionality'
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        dispatch(dataFetchingFinish())
                        return { textNavigate: '', isSuccessful: false, textNotification: 'Your Emirates ID cannot be recognized.\nPlease try again' }
                    }
                }
            } else {    
                if(maxAttemptsScan > 2){
                    formValues.status = 'In-process'
                    formValues.textNotification = "We encountered several unsuccessful attempts during the scanning process. Our team will <strong>manually review</strong> this document within <strong>the next 24 hours</strong>. However, <strong>some functionality</strong> within the app <strong>will be limited</strong> during this time.\nIf you <strong>agree</strong>, please click <strong>\"Request Manual Review.\"</strong> You can <strong>also update</strong> the document at <strong>any time</strong> and try again by going to <strong>\"Settings\"</strong> in your profile, or you can choose <strong>to retry now</strong>."
                    formValues.maxAttemptsWarning = true                  
                } else {
                    dispatch(dataFetchingFinish())
                    dispatch(setMaxAttemptsScan(maxAttemptsScan + 1))
                    return { textNavigate: '', isSuccessful: false, textNotification: 'Your Emirates ID cannot be recognized.\nPlease try again' }
                }
            }
        }
        if ('agent_create_unit_title_deed' in formValues) {
            //      console.log(scanDoc, 'scanDoc Title Deed')
            if (scanDoc !== null) {
                fileTypesToUpload.push('agent_create_unit_title_deed')
                if (scanDoc !== null) { //  && scanDoc?.document?.valid
                    const scanDocument = scanDoc?.document
                    if (scanDocument) {
                        formValues.status = scanDocument?.valid ? 'Approved' : 'In-process'
                        formValues.number = scanDocument?.number ?? ''
                        formValues.type = scanDocument?.type ?? ''
                        formValues.year = scanDocument?.year ?? ''
                        const scanDocumentProperty = scanDocument?.property
                        dispatch(setCreatePropertyFields(scanDocumentProperty))
                        formValues.property = {}
                        formValues.property.number = scanDocumentProperty?.number ?? ''
                        formValues.property.name = scanDocumentProperty?.name ?? ''
                        formValues.property.area = scanDocumentProperty?.area ?? ''
                        formValues.property.type = scanDocumentProperty?.type ?? ''
                        formValues.property.makani = scanDocumentProperty?.makani ?? ''
                        formValues.redirect = '/agent/modal/agent_create_unit_property/-/-'
                        formValues.textNotification = 'Your Title Deed was not recognized perfectly, Within 24 hours we will confirm your Title Deed and other agents will be able to use this units  functionality'
                    } else {
                        dispatch(dataFetchingFinish())
                        return { textNavigate: '', isSuccessful: false, textNotification: 'Your  Title Deed  cannot be recognized.\nPlease try again' }
                    }

                    //  dispatch(dataFetchingFinish())
                    //  return { textNavigate: '/agent/modal/agent_create_unit_property/-/-', isSuccessful: true, textNotification: 'Your Title Deed Good' }
                } else {
                    dispatch(dataFetchingFinish())
                    return { textNavigate: '/agent/modal/agent_create_unit_property/-/-', isSuccessful: true, textNotification: 'Your Title Deed in process.\n' }
                }
            } else {
                dispatch(dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: 'Your Title Deed cannot be recognized.\nPlease try again' }
            }
        }

        for (const fileType of fileTypesToUpload) {
            const doc: any = docsAgent.find((doc: any) => {
                return doc?.attributes?.Type?.[0]?.__component === documentTypes[fileType] && !doc?.attributes?.Statuses?.includes('Archived')
            })

            if (doc) {
                if (fileType === 'rera' || fileType === 'visa') {
                    const archDoc = await archiveDocument(doc.id)
                    if (archDoc.success) {
                        if (!(archDoc?.response?.error != null)) {
                            dispatch(documentsSlice.actions.documentUpdateArchive(archDoc?.response?.data))
                        }
                    }
                } else {
                    if (fileType === 'id_back') {
                        if (doc?.attributes?.LinkBack !== null && doc?.attributes?.LinkFront !== null) {
                            const archDoc = await archiveDocument(doc.id)
                            if (archDoc.success) {
                                if (!(archDoc?.response?.error != null)) {
                                    dispatch(documentsSlice.actions.documentUpdateArchive(archDoc?.response?.data))
                                }
                            }
                        }
                    }
                }
            }
        }
      
        const result = await uploadFiles(formValues, fileTypesToUpload)
        // console.log(result,"result")
        const filteredResults = result.filter((item: any) => item?.success && item?.response && item?.response?.data && item?.response?.data?.attributes)
        //  console.log(filteredResults,"filteredResults")
        if (filteredResults.length > 0) {
            dispatch(documentsSlice.actions.documentAddSuccess(filteredResults[0]?.response?.data))
            let typeDoc = 'Emirates ID'
            if (formValues.hasOwnProperty('rera')) {
                const docsM = store.getState().documents.documents
                dispatch(setStatuses(getAgentStatuses(docsM)))
                typeDoc = 'RERA card'
                // return { textNavigate: '/agent/units/nav', isSuccessful: true, textNotification: 'Within 24 hours we will confirm your documents and you will be able to use the corresponding functionality' }
            } else if (formValues.hasOwnProperty('visa')) {
                typeDoc = 'Passport'
                const docsM = store.getState().documents.documents
                dispatch(setStatuses(getAgentStatuses(docsM)))
            } else if (formValues.hasOwnProperty('id_back')) {
                const docsM = store.getState().documents.documents
                dispatch(setStatuses(getAgentStatuses(docsM)))
            }

            dispatch(dataFetchingFinish())
            dispatch(authSlice.actions.authFetching(false))
            return { scanDoc, textNavigate: 'agent_create_unit_title_deed' in formValues ? `/agent/modal/agent_create_unit_property/-/${filteredResults[0]?.response?.data?.id}` : formValues?.redirect || '/agent/settings', isSuccessful: true, textNotification: formValues.textNotification ?? '' ,   maxAttemptsWarning : formValues?.maxAttemptsWarning  }
        } else {
            dispatch(dataFetchingFinish())
            dispatch(authSlice.actions.authFetching(false))
            return { textNavigate: '', isSuccessful: false, textNotification: getErrorss(result[0]) }
        }
    } catch (error) {
        console.log(error, 'error1')
        dispatch(authSlice.actions.authFetching(false))
        dispatch(dataFetchingFinish())
        return { textNavigate: '', isSuccessful: false, textNotification: 'Error loaded file' }
    }
}
