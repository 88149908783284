import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router'
import CustomModal from './app/custom/CustomModal'
import AgentFooter from './components/agentModule/AgentFooter'
import AgentDashboard from './components/agentModule/dashboard/AgentDashboard'
import LandlordLayout from './components/landlordModule/LandlordLayout'
import LandlordSettings from './components/landlordModule/settings/LandlordSettings'

import { useAppDispatch, useAppSelector } from './hooks/redux'
import AuthPage from './pages/AuthPage'
import LandlordServices from './components/landlordModule/services'
import LandlordProfile from './components/landlordModule/profile/me/LandlordProfile'
import MyServicesSubscriptions from './components/landlordModule/services-subscriptions'

import AgentSettings from './components/agentModule/settings/AgentSettings'
import ChatList from './components/shared/newChat/chatList'
import NewChat from './components/shared/newChat/chat'
import AgentsRating from './components/agentModule/rating/AgentsRating'

import AgentModule from './components/agentModule/AgentModule'
import LandlordModule from './components/landlordModule/LandlordModule'

import AgentProfile from './components/agentModule/profile/me/AgentProfile'
import Search from './components/shared/search'

import useUserType from './hooks/UseUserType'
import { type RootState } from './store/store'
import AlertsVerified from './components/agentModule/alerts/AlertsVerified'

import MenuPage from './components/agentModule/menu/MenuPage'
import ContactsMy from './components/agentModule/profile/ContactsMy'
import SetUnitModal from './app/custom/customStoreModal/SetUnitModal'
import VoicePlayerLayout from './components/shared/voice-player/VoicePlayerLayout'
import MyReferrals from './components/shared/referrals/myReferrals'
import TutorialsList from './components/agentModule/settings/tutorials/TutorialsList'
import AgentSubscriptions from './components/agentModule/subscriptions/AgentSubscriptions'
import TermsSection from './components/agentModule/menu/termsPrivacy/TermsSection'
import PrivacySection from './components/agentModule/menu/termsPrivacy/PrivacySection'
import OrdersSection from './components/agentModule/menu/OrdersSection'
import AgentSubscriptionsChoose from './components/agentModule/subscriptions/AgentSubscriptionsChoose'
import AgentAddOnsServices from './components/agentModule/addOnsServices/AgentAddOnsServices'
import { useLocation } from 'react-router-dom'
import OtherAgentsProfile from './components/agentModule/profile/other/viewOtherProfile/OtherAgentsProfile'
import AgentPerformance from './components/agentModule/performance/AgentPerformance'
import { ACCESS_KEY, USER_ID_KEY } from './urls'
import AgentMemberships from './components/agentModule/settings/memberships/AgentMemberships'
import MyClients from './components/agentModule/clients'
import LandlordMenuPage from './components/landlordModule/menu'
import ViewingActions from './components/landlordModule/magic-actions/viewings'
import OffersActions from './components/landlordModule/magic-actions/offers'
import { getUserDevice } from './store/actionsCreators/appActions'
import AdvertiseActions from './components/landlordModule/magic-actions/advertises'
import ScheduleSh from './components/shared/schedule/ScheduleSh'
import BusinessMenuSection from './components/agentModule/menu/BusinessMenuSection'
import OrdersMenuSection from './components/agentModule/menu/OrdersMenuSection'
import PerformanceMenuSection from './components/agentModule/menu/PerformanceMenuSection'
import LegalMenuSection from './components/agentModule/menu/LegalMenuSection'
import MyUnitsSh from './components/shared/newUnits/MyUnitsSh'
import UnitsSh from './components/shared/newUnits/UnitsSh'
import LoadingDeleteAccount from './app/custom/loaders/LoadingDeleteAccount'
import FAQ from './components/shared/faq/FAQ'
import OtherAgentCatalogUnits from './components/agentModule/units/OtherAgentCatalogUnits'
import MyUnitsWithFiltersSh from './components/shared/newUnits/MyUnitsWithFiltersSh'
import MyCustomModalForView from './app/custom/customModalForView/MyCustomModalForView'
import NewChatV2 from './components/shared/newChat/chatV2'

function App(): JSX.Element {
    const isAuthenticated: boolean = useAppSelector((state: RootState) => state.auth.isAuthenticated)
    const deletingAccount = useAppSelector((state: RootState) => state.user.deletingAccount)
    const dispatch = useAppDispatch()
    const { userType } = useUserType()
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const navigate = useNavigate()
    const { pathname } = useLocation()

    useEffect(() => {
        dispatch(getUserDevice())
        if ((userID === null || jwt === null || !isAuthenticated) && pathname !== '/auth/register1') {
            navigate('/auth/login')
        }
    }, [])

    useEffect(() => {
        if (process.env.REACT_APP_IS_DEV === 'true') {
            window.addEventListener('push-notification', (e) => {
                if ((window as any)?.notifylink && (window as any)?.notifylink.length > 0) {
                    navigate((window as any)?.notifylink)
                }
            })
            return () => {
                window.removeEventListener('push-notification', () => { })
            }
        }

    }, [])




    if (deletingAccount) {
        return (<LoadingDeleteAccount />)
    }

    return (
        <div className="App">
            <Routes>
                {userType === 'Agent'
                    ? (
                        <>
                            <Route path="/agent" element={<VoicePlayerLayout><AgentModule /></VoicePlayerLayout>}>
                                <Route path="dashboard" element={<><AgentDashboard /></>} />
                                <Route path="profile/me" element={<> <AgentProfile /></>} />
                                <Route path="profile/me/contacts" element={<> <ContactsMy /></>} />
                                <Route path="profile/:id" element={<><OtherAgentsProfile /><AgentFooter /></>} />
                                <Route path="profile/:id/:from" element={<><OtherAgentsProfile /><AgentFooter /></>} />
                                <Route path="units/:from" element={<><UnitsSh /></>} />
                                <Route path="catalog_agent/:userId" element={<><OtherAgentCatalogUnits /></>} />
                                <Route path="my_units" element={<> <MyUnitsSh /></>} />
                                <Route path="performance" element={<><AgentPerformance /> </>} />
                                <Route path="schedule" element={<><AlertsVerified /><ScheduleSh /></>} />
                                <Route path="my_schedule" element={<><AlertsVerified /><ScheduleSh /></>} />
                                <Route path="settings" element={<><AgentSettings /> </>} />
                                <Route path="settings/tutorials" element={<><TutorialsList /> </>} />
                                <Route path="settings/addOns" element={<><AgentAddOnsServices /> </>} />
                                <Route path="settings/subscriptions" element={<><AgentSubscriptions /></>} />
                                <Route path="settings/memberships" element={<><AgentMemberships /></>} />
                                <Route path="settings/subscriptions/choose" element={<><AgentSubscriptionsChoose /></>} />
                                <Route path="settings/orders" element={<><OrdersSection /><AgentFooter /></>} />
                                <Route path="settings/terms" element={<><TermsSection /></>} />
                                <Route path="settings/privacy" element={<><PrivacySection /></>} />
                                <Route path="rating" element={<><AlertsVerified /><AgentsRating /> <AgentFooter /></>} />
                                <Route path="menu" element={<><MenuPage /><AgentFooter /></>} />
                                <Route path="menu/business" element={<><BusinessMenuSection /><AgentFooter /> </>} />
                                <Route path="menu/orders" element={<><OrdersMenuSection /><AgentFooter /> </>} />
                                <Route path="menu/performance" element={<><PerformanceMenuSection /><AgentFooter /> </>} />
                                <Route path="menu/legal" element={<><LegalMenuSection /><AgentFooter /> </>} />
                                <Route path="search" element={<><Search /><AgentFooter /></>} />
                                <Route path="referrals" element={<><MyReferrals /><AgentFooter /></>} />
                                <Route path="modal/:modalID/:unitID/:objectID" element={<CustomModal />} />
                                <Route path="modalView/:modalID/:unitID/:objectID/:from" element={<MyCustomModalForView />} />
                                <Route path="set_unit_modal/:modalID/:unitID/:objectID" element={<SetUnitModal />} />
                                <Route path="chats" element={<><AlertsVerified /><ChatList /><AgentFooter /></>} />
                                <Route path="chats/:id" element={<><AlertsVerified /><NewChatV2 /></>} />
                                <Route path={'myClients'} element={<><MyClients /><AgentFooter /></>} />
                                <Route path={'faq'} element={<><FAQ /><AgentFooter /></>}></Route>
                            </Route>
                        </>
                    )
                    : userType === 'Landlord'
                        ? <>
                            <Route path="/landlord" element={<VoicePlayerLayout><LandlordModule /></VoicePlayerLayout>}>
                                {/* <Route path='magic-dashboard' element={<LandlordLayout><MagicDashboard /></LandlordLayout>} />
                                <Route path='dashboard' element={<LandlordLayout><LandlordDashboard /></LandlordLayout>} /> */}
                                <Route path='units/:from?' element={<UnitsSh />} />
                                <Route path="my_units" element={<LandlordLayout><MyUnitsWithFiltersSh /></LandlordLayout>} />
                                <Route path='schedule' element={<ScheduleSh />} />
                                <Route path="services" element={<LandlordLayout><LandlordServices /></LandlordLayout>} />
                                <Route path="settings" element={<LandlordLayout><LandlordSettings dinamicRoutes={false} /></LandlordLayout>} />
                                <Route path="settings/tutorials" element={<LandlordLayout><TutorialsList /></LandlordLayout>} />
                                <Route path="settings/terms" element={<LandlordLayout><TermsSection /></LandlordLayout>} />
                                <Route path="settings/privacy" element={<LandlordLayout><PrivacySection /></LandlordLayout>} />
                                <Route path="profile/me" element={<LandlordLayout><LandlordProfile /></LandlordLayout>} />
                                <Route path="profile/:id/:from?" element={<LandlordLayout><OtherAgentsProfile /></LandlordLayout>} />
                                <Route path="services_subscriptions" element={<LandlordLayout><MyServicesSubscriptions /></LandlordLayout>} />
                                <Route path="search" element={<LandlordLayout><Search /></LandlordLayout>} />
                                <Route path="chats" element={<LandlordLayout><ChatList /></LandlordLayout>} />
                                <Route path="chats/:id" element={<LandlordLayout><NewChatV2 /></LandlordLayout>} />
                                <Route path="referrals" element={<LandlordLayout><MyReferrals /></LandlordLayout>} />
                                <Route path="modal/:modalID/:unitID?/:objectID?" element={<LandlordLayout><CustomModal /></LandlordLayout>} />
                                <Route path="modalView/:modalID/:unitID?/:objectID?/:from?" element={<MyCustomModalForView />} />
                                <Route path="set_unit_modal/:modalID/:unitID/:objectID" element={<SetUnitModal />} />
                                <Route path="menu" element={<LandlordLayout><LandlordMenuPage /></LandlordLayout>} />
                                <Route path="viewing_actions/:unitID/:viewingID" element={<LandlordLayout><ViewingActions /></LandlordLayout>} />
                                <Route path="offer_actions/:unitID/:offerID" element={<LandlordLayout><OffersActions /></LandlordLayout>} />
                                <Route path="advertise_actions/:unitID/:advertiseID" element={<LandlordLayout><AdvertiseActions /></LandlordLayout>} />
                            </Route>
                        </>
                        : null}

                {/* Маршруты для авторизации */}
                <Route path="/auth/login" element={<AuthPage />} />
                <Route path="/auth/register_scan_passport" element={<AuthPage />} />
                <Route path="/auth/register_scan_emirates" element={<AuthPage />} />
                <Route path="/auth/register_scan_emirates_front" element={<AuthPage />} />
                <Route path="/auth/write_yourself_fields" element={<AuthPage />} />
                <Route path="/auth/register1" element={<AuthPage />} />
                <Route path="/auth/register2" element={<AuthPage />} />
                <Route path="/auth/phone_verify" element={<AuthPage />} />
                <Route path="/auth/password_verify_email" element={<AuthPage />} />
                <Route path="/auth/reset_password_final_email" element={<AuthPage />} />
                <Route path="/auth/password_verify" element={<AuthPage />} />
                <Route path="/auth/reset_password_final" element={<AuthPage />} />
                <Route path="/auth/reset_password_way_phone" element={<AuthPage />} />
                <Route path="/auth/reset_password_way_email" element={<AuthPage />} />
                {/* Маршруты для неавторизованных пользователей */}
                {!isAuthenticated && (
                    <>
                        <Route path="/auth/*" element={<AuthPage />} />
                        <Route path="/auth/register1" element={<AuthPage />} />
                        <Route path="*" element={<Navigate to="/auth/login" />} />
                    </>
                )}

                {/* Редиректы для не существующих маршрутов  */}
                {isAuthenticated && (
                    <Route
                        path="*"
                        element={
                            isAuthenticated && userType === 'Agent'
                                ? (
                                    <Navigate to={'/agent/units/nav'} />
                                )
                                : isAuthenticated && userType === 'Landlord'
                                    ? (
                                        <Navigate to="/landlord/schedule" />
                                    )
                                    : (
                                        <Navigate to="/auth/login" />
                                    )
                        }
                    />
                )}
            </Routes>
            {process.env.REACT_APP_IS_DEV === 'true'
                ? <div className={'badge badge-danger position-fixed'}
                    style={{
                        bottom: '10px',
                        right: '10px',
                        zIndex: 999999
                    }}
                >
                    dev
                </div>
                : <></>
            }
        </div>
    )
}

export default App
