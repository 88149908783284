import React from 'react'
import AgentHeader from '../AgentHeader'

import AgentFooter from '../AgentFooter'
import AddOnsServiceBricks from './AddOnsServicesCards/AddOnsServiceBricks'
import AddOnsServiceArea from './AddOnsServicesCards/AddOnServicesArea'
import { getCurrentSub } from '../settings/ordersSubscriptions/SubscriptionsList'

export default function AgentAddOnsServices(): JSX.Element {
    const { activeSub } = getCurrentSub()

    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
            >
                <AgentHeader active="My Add-Ons" />
                <div
                    className="content d-flex flex-column flex-fill p-0 my-4"
                >
                    <div className="w-100 mw-450px mx-auto">

                        <AddOnsServiceBricks />

                        {activeSub !== "Premium" &&
                            <AddOnsServiceArea />}
                    </div>
                </div>
                <div className="m-0" style={{ height: '75px' }} />
            </div>
            <AgentFooter />
        </>
    )
}
