import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'
import { subtractDays } from '../../../functions/shared/date/subtractDays'
import { customSendNotify } from '../../../functions/shared/notify-helpers/customSendNotify'
import { ACCESS_KEY, urlAPI, USER_AREAS_EXTEND_COUNT, USER_CHOOSE_EXTEND_AREA, USER_ID_KEY } from '../../../urls'
import { setIsExtend, setUnitIDnotEnoughAreaForAdvertise } from '../../slices/advertisesSlice'
import { setWhatIsNotThereArea } from '../../slices/areasSlice'
import { dataFetchingFinish, dataFetchingStart, setButtonDriverExtendLocation } from '../../slices/modalSlice'
import { userFetchingSuccess, type ISettings } from '../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../store'
import { createSubscriptionAgentWithPaymentMethod } from '../subscriptionsActions/createSubscriptionAgent'

export const updateAreasForBricksPay = (formValues: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    //   console.log(formValues , "formValues")
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    try {
        dispatch(dataFetchingStart())

        const currentUser = getState().user.currentUser
        //    console.log(currentUser,"currentUser")
        const userSettings = (currentUser.Settings as ISettings[] | null)?.at(0)
        // const actualUser =   getState().areas.extendAreas
        const currentDubaiDate = getCurrentDubaiDate()

        const afterMonth = toUTCWithOffset(currentDubaiDate, -43200)
        const afterMonthMinusThreeDays = toUTCWithOffset(subtractDays(currentDubaiDate, 3), -43200)

        const storageCountAreasExtend = window.localStorage.getItem(USER_AREAS_EXTEND_COUNT)
        const countPack = (storageCountAreasExtend as string).split(' - ')[1]

        const createOrder = await dispatch(createSubscriptionAgentWithPaymentMethod(`pack_areas_${countPack}`, undefined, { isLiveMode: process.env.REACT_APP_IS_DEV === 'true', selectedPeriod: '' , areas : getState().areas.extendAreas }))

       
        if (createOrder?.isSuccessful === true) {
            window.localStorage.removeItem(USER_AREAS_EXTEND_COUNT)
            window.localStorage.removeItem(USER_CHOOSE_EXTEND_AREA)
            dispatch(setButtonDriverExtendLocation(false))
            dispatch(setIsExtend(false))
            dispatch(setUnitIDnotEnoughAreaForAdvertise(undefined))
            dispatch(setWhatIsNotThereArea(null))


            //   console.log(createOrder,"createOrder")

            // const sendObject = {
            //     method: 'PUT',
            //     collection: 'users',
            //     id: Number(userID),
            //     query: getQueryCurrentUser(),
            //     body: {
            //         Settings:
            //          [{
            //              ...userSettings,
            //              __component: 'settings.agent',
            //              Areas: formValues.locations_extension ?? userSettings?.Areas
            //          }]
            //     }
            // }

            // const response = await fetch(`${urlAPI}`, {
            //     method: 'POST',
            //     body: JSON.stringify(sendObject),
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Authorization: `Bearer ${jwt}`
            //     }
            // })
            // const dataJSON = await response.json()

            // if (dataJSON.success) {
            //     if (dataJSON.response.error != null) {
            //         dispatch(dataFetchingFinish())
            //         return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            //     } else {
            //         dispatch(userFetchingSuccess(dataJSON.response))

            //  dispatch(userFetchingSuccess(dataJSON.response))
            const bodyNotify = {
                mode: 'publish',
                RuleID: undefined,
                User: Number(userID),
                Text: 'Attention,area extension!',
                Type: 'Mobile',
                RuleData: getState().areas.extendAreas,
                publishedAt: null
            }
            await customSendNotify({
                ...bodyNotify,
                executeAt: afterMonth,
                Rule: 'user-area-extend'
            })

            await customSendNotify({
                ...bodyNotify,
                executeAt: afterMonthMinusThreeDays,
                Rule: 'user-area-extend-headsup'
            })

                  
            dispatch(dataFetchingFinish())
            return {  textNavigate: formValues.redirect , isSuccessful: true, textNotification: '' }
            //     }
            // } else {
            //     dispatch(dataFetchingFinish())
            //     return { textNavigate: '', isSuccessful: false, textNotification: '' }
            // }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
    }
}


