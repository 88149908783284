import { createSlice } from '@reduxjs/toolkit'

import { getCorrectExpDate } from '../../functions/agent/date/getCorrectExpDateForScaning'
import { getCorrectGender, getCountryCodes } from '../../functions/getDataForSelects/getCountries'
import {
    ACCESS_KEY,
    USER_CATEGORY_KEY,
    USER_DOB_KEY,
    USER_DOC_EXP_KEY,
    USER_DOC_NUM_KEY,
    USER_DOC_TYP_KEY,
    USER_EMAIL_KEY,

    USER_GENDER_KEY,
    USER_ID_KEY,
    USER_NAME_KEY,
    USER_NATIONALITY_KEY,
    USER_NUMBER_KEY,
    documentScanTypes,
    USER_ID_FRONT_ID_KEY, USER_ID_BACK_ID_LOAD,
    USER_TIME_INTERVAL_VERIFY_KEY,
    USER_ADVERTISE_DAYS,
    USER_AREAS_EXTEND_COUNT,
    FIRST_TUTORIAL_KEY,
    USER_CHOOSE_EXTEND_AREA,
} from '../../urls'
import { SEARCH_INFO_SWAL_SHOWN } from '../../components/shared/search/header'
import { removeNotifyByDeviceToken } from '../../functions/shared/notify-helpers/removeNotify'
import { store } from '../store'

export interface AuthState {
    isAuthenticated: boolean
    userCATEGORY: string | null
    userID: string | null
    userTOKEN: string | null
    error: string | null
    loading: boolean
    userNUMBER: string | null
    aes_code: string | null
    userEMAIL: string | null
}

function getInitialState (): AuthState {
    return {
        isAuthenticated: Boolean(window.localStorage.getItem(ACCESS_KEY) ?? ''),
        userID: window.localStorage.getItem(USER_ID_KEY) ?? null,
        userTOKEN: window.localStorage.getItem(ACCESS_KEY) ?? null,
        userCATEGORY: window.localStorage.getItem(USER_CATEGORY_KEY) ?? null,
        userNUMBER: window.localStorage.getItem(USER_NUMBER_KEY) ?? null,
        userEMAIL: window.localStorage.getItem(USER_EMAIL_KEY) ?? null,
        error: null,
        loading: false,
        aes_code: null
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        authFetching (state, action) {
            state.loading = action.payload
        },
        numberSuccess: (state, action) => {
            state.loading = false
            state.userNUMBER = action.payload
        },
        emailSuccess: (state, action) => {
            state.loading = false
            state.userEMAIL = action.payload
        },
        aes_codeSuccess: (state, action) => {
            state.loading = false
            state.aes_code = action.payload
        },
        loginSuccess: (state, action) => {
            state.loading = false
            state.error = null
            state.isAuthenticated = true
            state.userTOKEN = action.payload.jwt
            state.userID = action.payload.id
            state.userCATEGORY = action.payload.category
            window.localStorage.setItem(ACCESS_KEY, action.payload.jwt)
            window.localStorage.setItem(USER_ID_KEY, action.payload.id)
            window.localStorage.setItem(USER_CATEGORY_KEY, action.payload.category)
        },
        scanSuccess: (state, action) => {
            const docType = Object.keys(documentScanTypes).find(key => documentScanTypes[key] === action.payload.type)
            window.localStorage.setItem(USER_NAME_KEY, action.payload.name ?? '')
            window.localStorage.setItem(USER_GENDER_KEY, getCorrectGender(action.payload.gender ?? ''))
            window.localStorage.setItem(USER_DOB_KEY, getCorrectExpDate(action.payload.dob ?? ''))
            window.localStorage.setItem(USER_NATIONALITY_KEY, getCountryCodes(action.payload.nationality ?? ''))
            window.localStorage.setItem(USER_DOC_EXP_KEY, getCorrectExpDate(action.payload.expDate ?? ''))
            window.localStorage.setItem(USER_DOC_NUM_KEY, action.payload.number ?? '')
            window.localStorage.setItem(USER_DOC_TYP_KEY, docType ?? '')
            state.loading = false
        },

        loginFailed: (state, action) => {
            state.loading = false
            state.error = action.payload
        },

        registerSuccess: (state, action) => {
            state.loading = false
            state.error = null
            state.isAuthenticated = true
            state.userTOKEN = action.payload.jwt
            state.userCATEGORY = action.payload.category
            state.userID = action.payload.id

            window.localStorage.setItem(ACCESS_KEY, action.payload.jwt)
            window.localStorage.setItem(USER_ID_KEY, action.payload.id)
            window.localStorage.setItem(USER_CATEGORY_KEY, action.payload.category)

        },
        registerFailed: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        logoutSuccess: (state) => {
            state.loading = false
            state.error = null
            state.isAuthenticated = false
            state.userTOKEN = null
            state.userID = null
        },

        logout (state) {
            state.loading = false
            state.isAuthenticated = false
            window.localStorage.removeItem(ACCESS_KEY)
            window.localStorage.removeItem(USER_ID_KEY)
            window.localStorage.removeItem(USER_CATEGORY_KEY)
            window.localStorage.removeItem(FIRST_TUTORIAL_KEY)
            window.localStorage.removeItem(USER_NAME_KEY)
            window.localStorage.removeItem(USER_GENDER_KEY)
            window.localStorage.removeItem(USER_DOB_KEY)
            window.localStorage.removeItem(USER_NATIONALITY_KEY)
            window.localStorage.removeItem(USER_DOC_EXP_KEY)
            window.localStorage.removeItem(USER_DOC_NUM_KEY)

            window.localStorage.removeItem(USER_DOC_TYP_KEY)
            window.localStorage.removeItem(USER_EMAIL_KEY)

            window.localStorage.removeItem(USER_ID_FRONT_ID_KEY)
            window.localStorage.removeItem(USER_ID_BACK_ID_LOAD)
            window.localStorage.removeItem('offsetPosition')
            window.localStorage.removeItem('secondDateAdvertise')
            window.localStorage.removeItem('firstDateAdvertise')
            window.localStorage.removeItem('BSO_DEVELOP_MODE')
            window.localStorage.removeItem('scrollPositionHorizontal')
            window.localStorage.removeItem('scrollPositionUnitsInAgentProfile')
            window.localStorage.removeItem('scrollPosition')
            window.localStorage.removeItem('changingEmail')
            window.localStorage.removeItem(USER_TIME_INTERVAL_VERIFY_KEY)
            window.localStorage.removeItem(USER_ADVERTISE_DAYS)
            window.localStorage.removeItem(SEARCH_INFO_SWAL_SHOWN)
            window.localStorage.removeItem(USER_AREAS_EXTEND_COUNT)
            window.localStorage.removeItem(USER_CHOOSE_EXTEND_AREA)
        },
        logoutFailed: (state, action) => {
            state.loading = false
            state.error = action.payload
        }

    }
})

export const {
    loginSuccess,
    loginFailed,
    registerSuccess,
    registerFailed,
    logoutSuccess,
    logoutFailed,
    logout,
    scanSuccess

} = authSlice.actions

export default authSlice.reducer
