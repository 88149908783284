import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { timeStringInScheduler } from '../../../../functions/agent/date/timeStringInScheduler'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { generateBgAndBorderCol } from '../../../../functions/agent/schedule/generateBgAndBorderCol'
import { type RootState } from '../../../../store/store'
import formatPrice from '../../../../functions/shared/format/formatPrice'
import { nanoid } from 'nanoid'

import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
import UseUserType, { type UserType } from '../../../../hooks/UseUserType'
import Icon from '../../../../app/icon/Icon'
import { getBelongsUnitForOffer } from '../../../../functions/shared/schedule/getBelongsUnit'

export default function ScheduleOfferAttention({ offer }: any): JSX.Element {
    const { userType, userTypeLowercase } = UseUserType()
    const userID = window.localStorage.getItem(USER_ID_KEY)
    let notifyApp: UserType | 'BSO' | 'Both' = userType
    if (userID === REACT_APP_BSO_USER_ID) {
        notifyApp = 'BSO'
    }

    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    const [statusNotify, setStatusNotify] = useState(offer?.attributes?.Notify)

    const currentUnit = useAppSelector(
        (state: RootState) => state.units.units
    )?.filter(
        (unit) => unit.id === Number(offer?.attributes?.Unit?.data?.id)
    )[0]

    const [date, time] = timeStringInScheduler(
        offer?.attributes?.createdAt
    ).split(',')
    const [day, mounth, year] = date.split(' ')

    let unitNumber, buildName, location

    if (offer) {
        if (offer?.attributes?.Name) {
            [unitNumber, buildName, location] = offer?.attributes?.Name?.split(offer?.attributes?.Name.includes(' <-> ') ? ' <-> ' : '-')
        } else {
            [unitNumber, buildName, location] = [
                'Not number',
                'Not title',
                'Not location'
            ]
        }
    }

    const dispatch = useAppDispatch()
    const typeOffer = offer?.attributes?.Type?.[0]?.__component?.split('.')[1]
    let task = getBelongsUnitForOffer(offer)
    const [showBadge, setShowBadge] = useState(false)

    useEffect(() => {
        if (notifyApp === 'Agent') {
            if (task.type === 'MY UNIT' && offer?.attributes.User?.data?.id !== Number(userID) && statusNotify === 'Other Agent') {
                setShowBadge(true)
            } else if (task.type === 'BSO UNIT' && offer?.attributes.User?.data?.id === Number(userID) && statusNotify === 'Agent') {
                setShowBadge(true)
            } else if (task.type === 'OTHER AGENT UNIT' && offer?.attributes.User?.data?.id === Number(userID) && statusNotify === 'Agent') {
                setShowBadge(true)
            }
        } else if (notifyApp === 'BSO' && (statusNotify === 'BSO' || statusNotify === 'Both')) {
            setShowBadge(true)
        }
        task = getBelongsUnitForOffer(offer)
        setStatusNotify(offer?.attributes?.Notify)
    }, [offer, dispatch])

    const createdOfferAgent = useAppSelector(
        (state) => state.user.allUsers
    )?.filter(
        (user) => user.id === Number(offer?.attributes?.User?.data?.id)
    )[0]

    return (
        <Link

            // to={`/${userTypeLowercase}/modalView/${notifyApp === 'BSO' ? 'magic_' : ''}offer_modal/${String(offer?.attributes?.Unit?.data?.id)}/${String(offer.id)}/catalog?type=${String(offer?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`}
            to={

                notifyApp === 'BSO'
                    ? `/${userTypeLowercase}/modalView/magic_offer_modal/${String(offer?.attributes?.Unit?.data?.id) ?? 'not'}/${String(offer?.id) ?? 'not'}/catalog?type=${String(offer?.attributes?.Type?.[0]?.__component ?? 'not')}`
                    : offer?.attributes?.Unit?.data?.id && offer?.id ? `/${userTypeLowercase}/modalView/offer_modal/${String(offer?.attributes?.Unit?.data?.id)}/${String(offer?.id)}/${task.redirect}?type=${typeOffer ?? 'not'}` : "#"
            }

            className="text-decoration-none"
        >
            <div
                className={`d-flex flex-row row card shadow-sm g-0 p-2 px-3 m-0 align-items-center justify-content-between position-relative border border-1 border-${generateBgAndBorderCol(offer).borderColor} bg-light-${generateBgAndBorderCol(offer).borderColor}`}
            >
                {

                    showBadge
                        ? (
                            <span
                                className="position-absolute end-0 badge badge-danger w-auto mx-auto px-auto text-end"
                                style={{ top: -9 }}
                            >
                                {timeStringInScheduler(offer.attributes.updatedAt ?? offer.attributes.createAt)}
                            </span>
                        )
                        : null}
                <div className="d-flex flex-column col-2  lh-1 ">
                    {isDev
                        ? notifyApp !== 'BSO' && <span className={`position-absolute badge  badge-light-${generateBgAndBorderCol(offer).textColor} border border-${generateBgAndBorderCol(offer).borderColor} text-${generateBgAndBorderCol(offer).textColor} p-1`} style={{ top: '-7px', left: '-5px' }}>
                            {task.type}
                        </span>
                        : null}
                    <div className="w-50px d-flex flex-column align-items-center justify-content-start  px-2 col-2">

                        <span className="fs-4x text-dark">{`${day}`}</span>
                        <div>  <span className="text-dark fs-6 text-uppercase">{mounth}</span>
                            <span className=" fs-8 text-gray-700 mt-1">{`${year?.slice(2)}`}</span></div>

                        <div className={`badge badge-light-${generateBgAndBorderCol(offer).textColor} border text-${generateBgAndBorderCol(offer).textColor}  fs-9 px-2 d-inline`}>Offer</div>

                    </div>
                </div>
                <div className="d-flex flex-column col-6 ">
                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'door_open'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>
                            {unitNumber}, {buildName}
                        </span>
                    </span>
                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'location_on'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>
                            {location}{' '}
                        </span>
                    </span>
                    {userType === 'Agent'
                        ? (offer?.attributes?.Type[0]?.__component === 'offer.commercial' || offer?.attributes?.Type[0]?.__component === 'offer.sale-commercial'
                            ? <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                <Icon
                                    name={'person'}
                                    styles={{ minWidth: '12.9px' }}
                                />
                                <div className="">
                                    <span className={'text-dots-app'}>
                                        {offer?.attributes?.Type?.[0]?.BusinessName ?? 'No name'}
                                    </span>
                                    <div className="fs-8 text-gray-700">Client Name</div>
                                </div>
                            </span>

                            : <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                                <Icon
                                    name={'person'}
                                    styles={{ minWidth: '12.9px' }}
                                />
                                <div className="">
                                    <span className={'text-dots-app'}>
                                        {offer?.attributes?.Type?.[0]?.FirstName ?? 'No'}{' '}
                                        {offer?.attributes?.Type?.[0]?.LastName ?? 'name'}
                                    </span>
                                    <div className="fs-8 text-gray-700 my-n2">Client Name</div>
                                </div>
                            </span>

                        )
                        : <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                            <Icon
                                name={'person'}
                                styles={{ minWidth: '12.9px' }}
                            />
                            <span className={'text-dots-app'}>
                                {`${String(createdOfferAgent?.ContactInfo?.FirstName ?? 'Account')} ${String(createdOfferAgent?.ContactInfo?.FamilyName ?? 'deleted')}`}
                            </span>
                        </span>
                    }
                    {!currentUnit?.attributes?.Statuses?.includes('Door close') &&
                        <span className="fs-6 text-gray-800 d-flex align-items-center gap-2 fw-bold">
                            <Icon
                                name={'info'}
                                styles={{ minWidth: '12.9px' }}
                            />
                            <span className={'text-dots-app'}>
                                {currentUnit?.attributes?.Statuses?.includes('Door open')
                                    ? 'Door open'
                                    : ''}
                                {currentUnit?.attributes?.Statuses?.includes('Digital lock')
                                    ? 'Digital lock'
                                    : ''}
                                {currentUnit?.attributes?.Statuses?.includes('Keys in BSO office')
                                    ? 'Keys in BSO office'
                                    : ''}
                                {currentUnit?.attributes?.Statuses?.includes('Occupied')
                                    ? 'Occupied'
                                    : ''}
                                {currentUnit?.attributes?.Statuses?.includes('Building Management')
                                    ? 'Building Management'
                                    : ''}
                                {currentUnit?.attributes?.Statuses?.includes('Special Request')
                                    ? 'Special Request'
                                    : ''}
                            </span>
                        </span>}

                </div>
                <div className="d-flex flex-column col-4 align-items-end justify-content-center">
                    <div className="fs-8 text-gray-700">Offer status</div>
                    {offer?.attributes?.Statuses?.map((el: any) => (
                        <div key={nanoid()} className="fs-1x fw-bold text-dark lh-1 d-flex align-items-center justify-content-end">
                            <span
                                className={`me-1 badge h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderCol(offer).borderColor} bg-light-${generateBgAndBorderCol(offer).borderColor}`}
                            />
                            <span>
                                {
                                    el === "Negotiated by other agent"
                                        ? task.type === 'MY UNIT' ? 'Negotiated by yourself' : 'Negotiated by other agent'
                                        : el === "Negotiated by agent"
                                            ?
                                            task.type === 'MY UNIT' ? 'Negotiated by other agent' : 'Negotiated by yourself'
                                            : el
                                }
                            </span>
                        </div>
                    ))}
                    <div className="fs-8 text-gray-700">Offer price</div>
                    <div className="fs-5 text-dark pe-2 d-flex align-items-center gap-1">
                        <Icon
                            name={'monetization_on'}
                        />
                        <div>
                            {formatPrice(offer?.attributes?.Type?.[0]?.Offer)}
                            <span className="fs-8 text-gray-600">AED</span>
                        </div>
                    </div>
                </div>

            </div>
        </Link>
    )
}
